import './TransformacionSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import info_img from '../../../assets/images/img_info.png';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';

const CardInfo = ({ img, imgDesc, title, text }: any) => {

  return (
      <> 
        <Grid container className='card-info-container'>
          <Grid container item xs={12} className='img-item'>
            <img src={img} alt={imgDesc} className='info-img' />
          </Grid>
          <Grid container item xs={12} className='text-container'>
            <Typography className='title'>
              {title}
            </Typography>
            <Typography className='text'>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </>
  );
}

export const TransformacionSection = () => {
  return (
    <>

      {/* Banner section */}
      <Grid container className='Transformacion-banner-container'>
        <Grid item xs={12} lg={6} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
          <div className='right-border'></div>
          <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
        </Grid>
        <Grid item xs={12} lg={6} className='content-right'>
          <h1 className='title'><b>Transformación desde el aula</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Transformacion-description-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Subtítulo
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper rhoncus sapien sed commodo. Nunc iaculis
              ipsum ut vestibulum laoreet. Cras eu auctor libero. orbi mattis erat vitae arcu ornare aliquet. Curabitur a tincidunt ex.
              Nam quam libero, cursus nec lacinia et, eleifend facilisis purus. Curabitur bibendum lectus mollis.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* types section */}
      <Grid container className='Transformacion-types-container'>
        <Grid item xs={12} lg={6} className='card-container'>
          <CardInfo 
            img={info_img}
            imgDesc="Imagen tipo de transformación"
            title="Directivos, Docentes orientadores y Docentes de aula"
            text="Auctor libero. Morbi mattis erat vitae arcu ornareLorem ipsum dolor sit
            amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
            tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim
            ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation"
          />
        </Grid>
        <Grid item xs={12} lg={6} className='card-container'>
          <CardInfo 
            img={info_img}
            imgDesc="Imagen tipo de transformación"
            title="Docentes de apoyo pedagógico"
            text="Auctor libero. Morbi mattis erat vitae arcu ornareLorem ipsum dolor sit
            amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
            tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim
            ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation"
          />
        </Grid>
      </Grid>

    </>
  );
};