import React from 'react';
import Grid from '@mui/material/Grid';
import { VocesSection } from '../../components/LandingPage/VocesFamiliasEstudiantes';

export const VocesFamiliasEstudiantes = () => {
  return (
    <>
      <Grid container className='landing-container'>
        <Grid item xs={12}>
            <VocesSection />
        </Grid>
      </Grid>
    </>
  );
};