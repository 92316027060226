import React from 'react';
import Grid from '@mui/material/Grid';
import { HomeSection } from '../../components/LandingPage/HomeSection';

export const Home = () => {
  return (
    <>
      <Grid container className='landing-container'>
        <Grid item xs={12}>
          <HomeSection />
        </Grid>
      </Grid>
    </>
  );
};