import './Navigation.scss';
import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';
import logo_programa from '../../../assets/images/logo_educacion_inclusiva.png';

const Navigation = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  const goToLiderazgo = () => {
    navigate('/liderazgo-desde-el-ministerio');
  };

  const goToRegiones = () => {
    navigate('/cambio-en-las-regiones');
  };

  const goToAula = () => {
    navigate('/transformacion-desde-el-aula');
  };

  const goToVoces = () => {
    navigate('/voces-familias-y-estudiantes');
  };

  return (
    <header className="navigation-container">
		<div className='logo-principal' onClick={goToHome}>
			<img className="logo-nav" alt="Logo educación inclusiva" src={logo_programa} />
		</div>
		<Grid container className='top-navigation'>
			<img className="logo-1-nav" alt="Logo Ministerio de educación" src={logo_educacion} />
			<div className='right-border'></div>
			<img className="logo-2-nav" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
		</Grid>
		<Grid container className='bot-navigation'>
			<div className="section-container">
				<p><b>Iniciativas en acción:</b></p>
			</div>
			<div className="section-container nav-item" onClick={goToLiderazgo}>
				<p>Liderazgo desde el Ministerio</p>
			</div>
			<div className='right-border'></div>
			<div className="section-container nav-item" onClick={goToRegiones}>
				<p>El cambio en las regiones</p>
			</div>
			<div className='right-border'></div>
			<div className="section-container nav-item" onClick={goToAula}>
				<p>Transformación desde el aula</p>
			</div>
			<div className='right-border'></div>
			<div className="section-container nav-item" onClick={goToVoces}>
				<p>Voces de las familias y estudiantes</p>
			</div>
		</Grid>
    </header>
  );
};

export default Navigation;
