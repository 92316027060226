import React from 'react';
import Grid from '@mui/material/Grid';
import { LiderazgoSection } from '../../components/LandingPage/LiderazgoMinisterio';

export const LiderazgoMinisterio = () => {
  return (
    <>
      <Grid container className='landing-container'>
        <Grid item xs={12}>
            <LiderazgoSection />
        </Grid>
      </Grid>
    </>
  );
};