import React from 'react';
import Grid from '@mui/material/Grid';
import { TransformacionSection } from '../../components/LandingPage/TransformacionAula';

export const TransformacionAula = () => {
  return (
    <>
      <Grid container className='landing-container'>
        <Grid item xs={12}>
            <TransformacionSection />
        </Grid>
      </Grid>
    </>
  );
};