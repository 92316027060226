import React from 'react';
import './App.scss';
import Router from './routes';
import customTheme from './themeConfig';
import { ThemeProvider } from '@mui/material/styles';
import { HashRouter } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <HashRouter>
        <Router />
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
