import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

const listClassStyles = [
  // Registerbox
  '.text-info',
  '.btn-txt',
  // //Informationbox
  '.text-info-st',
  '.text-info2-st',
  '.text-info-t',
  '.text-info2',
  '.text-info-t-1',
  '.text-info2-1',
  '.text-info-t-2',
  '.text-info2-2',
  '.text-info-t-3',
  '.text-info2-3',
  // //Curriculum
  '.title',
  '.underline',
  '.btn-txt-2',
  //HowToSignUp
  '.title-2',
  '.underline-2',
  '.subtitle',
  '.info-title',
  '.text-box-info-1',
  '.info-title-2',
  '.text-box-info-2',
  '.btn-txt-3',
  '.btn-txt-4',
  // Testimony
  '.title',
  '.flex-items',
  '.flex-items-2',
  '.flex-items-3'
];

const letraAumentar = document.getElementById('letra-aumentar');

letraAumentar?.addEventListener(
  'click',
  () => {
    const percent = (
      document.getElementById('letter-percent') as HTMLInputElement
    ).value;

    const int_percent = Number(percent.replace('%', ''));

    listClassStyles.forEach((ele) => {
      console.log('****************');

      console.log(ele);
      changeFontSize(ele, int_percent, '+');
    });
  },
  false
);

const letraDisminuir = document.getElementById('letra-disminuir');

letraDisminuir?.addEventListener(
  'click',
  () => {
    const percent = (
      document.getElementById('letter-percent') as HTMLInputElement
    ).value;

    const int_percent = Number(percent.replace('%', ''));

    listClassStyles.forEach((ele) => {
      changeFontSize(ele, int_percent, '-');
    });
  },
  false
);

const defaultConfig = document.getElementById('defaul-config');

defaultConfig?.addEventListener(
  'click',
  () => {
    listClassStyles.forEach((clase) => {
      const elemento = document.querySelector(clase) as HTMLInputElement;
      const computedStyle = window.getComputedStyle(elemento!);
      const fontSize = computedStyle.getPropertyValue('font-size');

      let int_fontSize = Number(fontSize.replace('px', ''));

      const newFontSize = int_fontSize / (percentGlobal / 100);

      elemento.setAttribute(
        'style',
        'font-size:' + newFontSize + 'px!important'
      );
    });
  },
  false
);

let porcentaje100 = true;
let percentGlobal = 100;

const changeFontSize = (clase: string, percent: number, operation: string) => {
  const elemento = document.querySelector(clase) as HTMLInputElement;
  const computedStyle = window.getComputedStyle(elemento!);
  const fontSize = computedStyle.getPropertyValue('font-size');

  let int_fontSize = Number(fontSize.replace('px', ''));

  if (percent > 100) {
    int_fontSize =
      operation === '+'
        ? int_fontSize / ((percent - 10) / 100)
        : int_fontSize / ((percent + 10) / 100);
    porcentaje100 = false;
  } else {
    int_fontSize =
      porcentaje100 === false
        ? int_fontSize / ((percent + 10) / 100)
        : int_fontSize;
    porcentaje100 = true;
  }

  const newFontSize = int_fontSize * (percent / 100);
  percentGlobal = percent;
  elemento.setAttribute('style', 'font-size:' + newFontSize + 'px!important');
};
