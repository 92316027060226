import './Footer.scss'
import React from 'react';
import logo_programa from '../../../assets/images/logo_educacion_inclusiva_negro.png';
import logo_educacion from '../../../assets/images/logo_educacion_negro.png';
import logo_unal from '../../../assets/images/logo_unal_negro.png';
import { Grid } from '@mui/material';


class Footer extends React.Component{
  	render(){
		return(
			<footer className="footer-container">
				<Grid container className='top-footer'>
					<Grid container item xs={12} lg={8} className="logos-footer">
						<Grid item xs={12} lg={4} className='logo-right'>
							<img className="logo-1-footer" alt="Logo educación inclusiva" src={logo_programa} />
						</Grid>
						<Grid item xs={7} lg={3} className='logo-right right-border'>
							<img className="logo-2-footer" alt="Logo Ministerio de educación" src={logo_educacion} />
						</Grid>
						<Grid item xs={5} lg={3}>
							<img className="logo-2-footer" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
						</Grid>
					</Grid>
					<Grid container item xs={12} lg={4} className="nav-footer">
						<nav>
							<a href="index.html#">Mapa del sitio</a>
							<a href="index.html#">Centro de recursos</a>
							<a href="index.html#">Directorio</a>
							<a href="index.html#">Preguntas frecuentes</a>
							<a href="index.html#">Contacto</a>
						</nav>
					</Grid>
				</Grid>
				<Grid container className='bot-footer'>
					<Grid container item xs={12} lg={6}>
						<p>MEN | www.mineducacion.gov.co</p>
					</Grid>
					<Grid container item xs={12} lg={6} sx={{justifyContent: { xs: "start", lg: "end" }}}>
						<p>© 2024 MEN. All rights reserved.</p>
					</Grid>
				</Grid>
			</footer>
		)
  	}
}

export default Footer