import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";

const customTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 540,
        md: 720,
        lg: 960,
        xl: 1302,
      },
    },
    typography: {
      h1: {
        fontSize: "32px",
      },
      h2: {
        fontSize: "24px",
      },
      h3: {
        fontSize: "20px",
      },
      h4: {
        fontSize: "18px",
      },
      h5: {
        fontSize: "16px",
      },
      h6: {
        fontSize: "14px",
      },
      h7: {
        fontSize: "12px",
      },
    },
  },
  esES
);

export default customTheme;
