import './RegionesSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';
import icon_play from '../../../assets/images/icon_play_orange.svg';
import { Dialog, DialogContent, Button } from '@mui/material';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import pildora_1 from '../../../assets/images/pildora_01.webp';
import pildora_2 from '../../../assets/images/pildora_02.webp';
import pildora_3 from '../../../assets/images/pildora_03.webp';
import pildora_4 from '../../../assets/images/pildora_04.webp';
import pildora_5 from '../../../assets/images/pildora_05.webp';
import pildora_6 from '../../../assets/images/pildora_06.webp';
import pildora_7 from '../../../assets/images/pildora_07.webp';
import pildora_8 from '../../../assets/images/pildora_08.webp';
import pildora_9 from '../../../assets/images/pildora_09.webp';
import pildora_10 from '../../../assets/images/pildora_10.webp';
import pildora_11 from '../../../assets/images/pildora_11.webp';
import pildora_12 from '../../../assets/images/pildora_12.webp';
import pildora_13 from '../../../assets/images/pildora_13.webp';
import pildora_14 from '../../../assets/images/pildora_14.webp';
import pildora_15 from '../../../assets/images/pildora_15.webp';
import pildora_16 from '../../../assets/images/pildora_16.webp';
import pildora_17 from '../../../assets/images/pildora_17.webp';
import pildora_18 from '../../../assets/images/pildora_18.webp';
import pildora_19 from '../../../assets/images/pildora_19.webp';
import pildora_20 from '../../../assets/images/pildora_20.webp';
import pildora_21 from '../../../assets/images/pildora_21.webp';
import pildora_22 from '../../../assets/images/pildora_22.webp';
import pildora_23 from '../../../assets/images/pildora_23.webp';
import pildora_24 from '../../../assets/images/pildora_24.webp';
import pildora_25 from '../../../assets/images/pildora_25.webp';
import pildora_26 from '../../../assets/images/pildora_26.webp';
import pildora_27 from '../../../assets/images/pildora_27.webp';
import memory_1 from '../../../assets/images/1.titulo.webp';
import memory_2 from '../../../assets/images/2.INTRO.webp';
import memory_3 from '../../../assets/images/3.PANEL 1.webp';
import memory_4 from '../../../assets/images/4.PANEL 1 (29am).webp';
import memory_5 from '../../../assets/images/5.conformacion de grupos.webp';
import memory_6 from '../../../assets/images/6.CULTURAS (principios).webp';
import memory_7 from '../../../assets/images/7.POLITICAS (el decalogo).webp';
import memory_8 from '../../../assets/images/8.PRACTICAS(la receta).webp';
import memory_9 from '../../../assets/images/9.experiencias.webp';
import memory_10 from '../../../assets/images/10.GRUPO MUSICAL.webp';
import memory_11 from '../../../assets/images/1. UNAL _panel de experiencias.webp';
import memory_12 from '../../../assets/images/2. UNAL_culturas.webp';
import memory_13 from '../../../assets/images/3. UNAL_Políticas .webp';
import memory_14 from '../../../assets/images/4. UNAL_Practicas.webp';
import classroom from '../../../assets/images/classroom.png';

export const RegionesSection = () => {

  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<{ src: string; title: string }[] | null>(null);

  const handleOpen = (index: number) => {
    setSelectedImages(pildoras_bundle[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImages(null);
  };

  const [open2, setOpen2] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpen2 = (image: string) => {
    setSelectedImage(image);
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setSelectedImage(null);
  };

  const pildoras_img = [
    pildora_1,
    pildora_4,
    pildora_6,
    pildora_9,
    pildora_11,
    pildora_13,
    pildora_16,
    pildora_18,
    pildora_20,
    pildora_22,
    pildora_25,
  ];

  const pildoras_bundle= [[
    { src: pildora_1, title: 'Píldora 1' },
    { src: pildora_2, title: 'Píldora 2' },
    { src: pildora_3, title: 'Píldora 3' },
  ], [
    { src: pildora_4, title: 'Píldora 4' },
    { src: pildora_5, title: 'Píldora 5' },
  ], [
    { src: pildora_6, title: 'Píldora 6' },
    { src: pildora_7, title: 'Píldora 7' },
    { src: pildora_8, title: 'Píldora 8' },
  ], [
    { src: pildora_9, title: 'Píldora 9' },
    { src: pildora_10, title: 'Píldora 10' },
  ], [
    { src: pildora_11, title: 'Píldora 11' },
    { src: pildora_12, title: 'Píldora 12' },
  ], [
    { src: pildora_13, title: 'Píldora 13' },
    { src: pildora_14, title: 'Píldora 14' },
    { src: pildora_15, title: 'Píldora 15' },
  ], [
    { src: pildora_16, title: 'Píldora 16' },
    { src: pildora_17, title: 'Píldora 17' },
  ], [
    { src: pildora_18, title: 'Píldora 18' },
    { src: pildora_19, title: 'Píldora 19' },
  ], [
    { src: pildora_20, title: 'Píldora 20' },
    { src: pildora_21, title: 'Píldora 21' },
  ], [
    { src: pildora_22, title: 'Píldora 22' },
    { src: pildora_23, title: 'Píldora 23' },
    { src: pildora_24, title: 'Píldora 24' },
  ], [
    { src: pildora_25, title: 'Píldora 25' },
    { src: pildora_26, title: 'Píldora 26' },
    { src: pildora_27, title: 'Píldora 27' },
  ]];

  const relatorias1 = [
    memory_1,
    memory_2,
    memory_3,
    memory_4,
    memory_5,
    memory_6,
    memory_7,
    memory_8,
    memory_9,
    memory_10,
  ];

  const relatorias2 = [
    memory_11,
    memory_12,
    memory_13,
    memory_14,
  ];

  return (
    <>

      {/* Banner section */}
      <Grid container className='Regiones-banner-container'>
        <Grid item xs={12} lg={6} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
          <div className='right-border'></div>
          <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
        </Grid>
        <Grid item xs={12} lg={6} className='content-right'>
          <h1 className='title'><b>El cambio en las regiones</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Regiones-description-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              <b>Tejiendo redes desde las regiones:</b> Inclusión y equidad en la educación para la construcción de país
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Este espacio tiene como propósito principal <b>conectar a líderes y referentes en inclusión y equidad en la educación de todo el país</b>, promoviendo el <b>intercambio de conocimientos y prácticas significativas</b> que reflejan la diversidad regional. A través de este intercambio, se promueven estrategias transformadoras en la atención de <b>niñas, niños, adolescentes y jóvenes</b> con discapacidad, trastornos del aprendizaje y/o del comportamiento, capacidades o talentos excepcionales, así como aquellos que requieren apoyo académico especial debido a condiciones de salud.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Pildoras section */}
      <Grid container className='Regiones-pildoras-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Píldoras de responsabilidades
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              En esta sección se presentan píldoras informativas sobre las responsabilidades compartidas por los funcionarios de las Secretarías de Educación. El propósito es proporcionar información, inspirar y fomentar la conexión entre los actores de las diferentes áreas, con el fin de que, desde sus roles y mediante acciones articuladas, contribuyan a promover transformaciones orientadas a garantizar la inclusión y la equidad en la educación.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='pildoras-container'>
          {pildoras_img.map((image, index) => (
            <Grid
              item
              container
              xs={12}
              lg={3}
              justifyContent={'center'}
              key={index}
            >
              <img
                src={image}
                alt={`Píldora ${index + 1}`}
                className='pildora-img'
                onClick={() => handleOpen(index)} // Abre el modal al hacer clic
                style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clickeable
              />
            </Grid>
          ))}

          <Dialog open={open} onClose={handleClose} maxWidth='xl'>
            <DialogContent className='pildoras-modal' style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
              {selectedImages && selectedImages.map((image, idx) => (
                <div key={idx} style={{ textAlign: 'center' }}>
                  <Typography className='subtitle'>
                    {image.title}
                  </Typography>
                  <img
                    src={image.src}
                    alt={`Píldora ${idx + 1}`}
                    style={{
                      width: '400px',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                </div>
              ))}
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>

      {/* memories section */}
      <Grid container className='Regiones-memories-container'>
        <Grid container justifyContent={'center'}>
          <Grid xs={11}>
            <hr className='separator'/>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Memorias Segundo Encuentro Nacional de Líderes y Referentes en inclusión y equidad en la educación
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Los espacios de diálogo de este encuentro facilitaron el intercambio de ideas y perspectivas desde las dimensiones de culturas, políticas y prácticas. Este ejercicio colectivo quedó reflejado en relatorías gráficas que se presentan en esta sección, con el propósito de visualizar y comprender lo sucedido, así como de seguir impulsando el diálogo y la reflexión sobre estos temas fundamentales.
            </Typography>
          </Grid>
        </Grid>
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            1024: { slidesPerView: 3 },
            600: { slidesPerView: 1 },
          }}
          pagination={{ clickable: true }}
          className='memories-carousel'
        >
          {relatorias1.map((image, index) => (
            <SwiperSlide key={index} className='memories-carousel-slide'>
              <img
                src={image}
                alt="relatoria"
                className='memory-img'
                onClick={() => handleOpen2(image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            1024: { slidesPerView: 2 },
            600: { slidesPerView: 1 },
          }}
          pagination={{ clickable: true }}
          className='memories-carousel'
        >
          {relatorias2.map((image, index) => (
            <SwiperSlide key={index} className='memories-carousel-slide'>
              <img
                src={image}
                alt="relatoria"
                className='memory-img'
                onClick={() => handleOpen2(image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Dialog open={open2} onClose={handleClose2} maxWidth='lg'>
          <DialogContent style={{ padding: 0 }}>
            {selectedImage && (
              <img
                src={selectedImage}
                alt='Imagen ampliada'
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      </Grid>

      {/* Media section */}
      <Grid container className='Regiones-media-container'>
        <Grid container justifyContent={'center'}>
          <Grid xs={11}>
            <hr className='separator'/>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={10}>
            <Typography className='title'>
              Recursos creados bajo el Convenio CO1.PCCNTR.672706 (2024)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Accede a las grabaciones de los tres webinars realizados el 6 de diciembre de 2024, donde se compartieron reflexiones para promover una educación inclusiva con énfasis en estudiantes que requieren apoyos y ajustes razonables. Estos espacios fueron diseñados para inspirar, orientar y servir como material de referencia para todos los actores comprometidos con el fortalecimiento territorial de la educación inclusiva.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={4} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Webinar 1: nombre del webinar
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Descripción y contexto del webinar 1
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={4} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Webinar 2: nombre del webinar
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Descripción y contexto del webinar 2
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={4} className='media-section last-item'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Webinar 3: nombre del webinar
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Descripción y contexto del webinar 3
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Classroom section */}
      <Grid container className='Regiones-classroom-container'>
        <Grid container justifyContent={'center'}>
          <Grid xs={11}>
            <hr className='separator'/>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={8}>
            <Typography className='title'>
              Espacio virtual de interacción y colaboración entre las Secretarías de Educación
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <Grid xs={11} lg={4} className='img-container'>
            <img src={classroom} alt='Icono de classroom' className='info-img' />
          </Grid>
          <Grid item xs={12} lg={5} className='text-container'>
          <Grid xs={11}>
            <Typography className='text-info'>
              En este espacio se presentan <b>10 retos clave que enfrentan las Secretarías de Educación</b>, acompañados de recomendaciones prácticas para facilitar su resolución. Además, se ofrece un <b>entorno interactivo</b> que permite <b>compartir ideas y sugerencias</b>, así como <b>intercambiar datos de contacto entre las Secretarías</b>, fomentando el acompañamiento mutuo y la articulación para abordar estos desafíos de manera conjunta.
            </Typography>
            <div className='button-container'>
                <Button type="submit" variant="contained" fullWidth className='submit-button'>
                    <Typography className='btn-txt'>
                      Classroom
                    </Typography>
                </Button>
            </div>
          </Grid>
        </Grid>
        </Grid>
      </Grid>

    </>
  );
};
