import React from 'react';
import Grid from '@mui/material/Grid';
import { RegionesSection } from '../../components/LandingPage/CambioRegiones';

export const CambioRegiones = () => {
  return (
    <>
      <Grid container className='landing-container'>
        <Grid item xs={12}>
            <RegionesSection />
        </Grid>
      </Grid>
    </>
  );
};