import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Navigation from '../layouts/LandingLayout/Navigation/Navigation';
import Footer from '../layouts/LandingLayout/Footer/Footer';
import { Home } from '../pages/home';
import { LiderazgoMinisterio } from '../pages/liderazgoMinisterio';
import { CambioRegiones } from '../pages/cambioRegiones';
import { TransformacionAula } from '../pages/transformacionAula';
import { VocesFamiliasEstudiantes } from '../pages/vocesFamiliasEstudiantes';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<><Navigation /><Home /><Footer /></>} />
      <Route path='/liderazgo-desde-el-ministerio' element={<><Navigation /><LiderazgoMinisterio /><Footer /></>} />
      <Route path='/cambio-en-las-regiones' element={<><Navigation /><CambioRegiones /><Footer /></>} />
      <Route path='/transformacion-desde-el-aula' element={<><Navigation /><TransformacionAula /><Footer /></>} />
      <Route path='/voces-familias-y-estudiantes' element={<><Navigation /><VocesFamiliasEstudiantes /><Footer /></>} />
    </Routes>
  );
}


