import './LiderazgoSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import icon_play from '../../../assets/images/play_icon_2.svg';
import icon_download from '../../../assets/images/icon_download.png';
import icon_paper from '../../../assets/images/icon_paper.png';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';
import Box from '@mui/material/Box';
import pieza_grafica_1 from '../../../assets/images/Pieza_grafica_1.png';
import pieza_grafica_2 from '../../../assets/images/Pieza_grafica_2.png';
import pieza_grafica_3 from '../../../assets/images/Pieza_grafica_3.png';
import pieza_grafica_4 from '../../../assets/images/Pieza_grafica_4.png';
import { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';

const CardInfo = ({ text, link, color }: any) => {

  return (
      <>  
        <a href={link} target='__blank' className='link-cards'>
          <Grid container className={`card-info-container ${color}`}>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </a>
      </>
  );
}

const CardText = ({ text, position, link }: any) => {

  return (
      <>  
        <hr className={position}/>
        <a href={link} target='__blank' className='link-cards-text'>
          <Grid container className='card-info-container'>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </a>
      </>
  );
}

export const LiderazgoSection = () => {

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpen = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const images = [
    pieza_grafica_4,
    pieza_grafica_3,
    pieza_grafica_2,
    pieza_grafica_1,
  ];
  
  return (
    <>

      {/* Banner section */}
      <Grid container className='Liderazgo-banner-container'>
        <Grid item xs={12} lg={5} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
          <div className='right-border'></div>
          <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
        </Grid>
        <Grid item xs={12} lg={7} className='content-right'>
          <h1 className='title'><b>El Liderazgo del Ministerio de Educación Nacional</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Liderazgo-description-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={10}>
            <Typography className='title'>
              Lideramos transformaciones en culturas, políticas y prácticas para garantizar una educación inclusiva y equitativa para todas las personas
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              En el Ministerio de Educación Nacional, somos agentes de cambio que conectan políticas nacionales con su implementación en los territorios. Trabajamos en equipo con secretarías, instituciones y comunidades para construir un sistema educativo que respete, valore y responda a la diversidad, garantizando educación de calidad para todos.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Media section */}
      <Grid container className='Liderazgo-media-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11} lg={10}>
            <Typography className='title'>
              Recursos creados bajo el Convenio CO1.PCCNTR.672706 (2024)
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Inclusión: reflexión y acción
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Videoclip que detalla los lineamientos de política de inclusión y equidad educativa, destacando el rol clave de los funcionarios del MEN en su implementación.
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Territorios que se transforman: Narrativas de equidad
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={0} md={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Typography className='text'>
                    Corto documental que muestra la implementación del convenio y su impacto en la construcción de una educación equitativa.
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* images section */}
      <Grid container className='Liderazgo-images-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Piezas gráficas que incentivan la toma de conciencia
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className='graphic-container'>
          {images.map((image, index) => (
            <Grid
              item
              container
              xs={12}
              lg={3}
              justifyContent={'center'}
              key={index}
            >
              <img
                src={image}
                alt={`Pieza gráfica ${index + 1}`}
                className='graphic-img'
                onClick={() => handleOpen(image)} // Abre el modal al hacer clic
                style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clickeable
              />
            </Grid>
          ))}

          <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogContent style={{ padding: 0 }}>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt='Imagen ampliada'
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'block',
                  }}
                />
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>

      {/* Repository section */}
      <Grid container className='Liderazgo-repository-container'>
        <Grid container item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Biblioteca de políticas y lineamientos
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} lg={8} className='resources-section'>
            <Grid container className='cards-info-container'>
              <Grid container item xs={12} lg={2} className='img-item'>
                <img src={icon_download} alt="Icono de descarga" className='info-img' />
              </Grid>
              <Grid container item xs={10} lg={9} className='text-container-2'>
                <Typography className='text'>
                  Lineamientos, recursos formativos y orientaciones
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://www.colombiaaprende.edu.co/sites/default/files/files_public/archivos_contenidos/AF%20LINEAMIENTOS%20DE%20POLI%CC%81TICA%20ACCESIBLE.pdf"
                text={<><b>Lineamientos de política para la inclusión y la equidad en educación. Educación para todas las personas sin excepción  (MEN, 2021). 
                </b> Documento marco que orienta políticas educativas inclusivas desde una visión transformadora.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://www.mineducacion.gov.co/1780/articles-363488_recurso_17.pdf"
                text={<><b>Inclusión y equidad: hacia la construcción de una política de educación inclusiva para Colombia.
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://www.mineducacion.gov.co/1780/articles-363488_recurso_27.pdf"
                text={<><b>Inclusión y equidad en el proceso de evaluación de la calidad de la educación.
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://www.colombiaaprende.edu.co/contenidos/coleccion/emociones-conexion-vital"
                text={<><b>Emociones, Conexión Vital: inclusión y equidad para trayectorias educativas completas.
                </b> Material formativo para docentes y entidades territoriales.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://www.saldarriagaconcha.org/centro-de-recursos-maestros-por-la-inclusion/?gad_source=1&gclid=Cj0KCQjw1Yy5BhD-ARIsAI0RbXZEScUmvsrz_iZaB73SsxRov2L_BzAGpBYLFdYSB1V8j8OVSqzVeVgaAnx-EALw_wcB"
                text={<><b> Centro de Recursos Maestros por la Inclusión. 
                </b> Plataforma de recursos y herramientas para docentes comprometidos con la inclusión y la equidad.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://www.facebook.com/groups/maestrosporlainclusion/?locale=es_LA"
                text={<><b>Grupo de Facebook. Maestros por la inclusión. 
                </b> Comunidad virtual de intercambio y apoyo para docentes que impulsan prácticas inclusivas.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://www.saldarriagaconcha.org/wp-content/uploads/2019/01/Libro2-Toma-de-Conciencia.pdf"
                text={<><b>Toma de conciencia, sentir, pensar y actuar.
                </b> Documento de utilidad que orienta a las comunidades educativas en el cambio de imaginarios, actitudes y comportamientos para la educación inclusiva.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://www.mineducacion.gov.co/1759/articles-360293_foto_portada.pdf"
                text={<><b>Documento de orientaciones técnicas, administrativas y pedagógicas para la atención educativa a estudiantes con discapacidad en el marco de la educación inclusiva.
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://escuelasecretarias.mineducacion.gov.co/sites/default/files/2022-10/9.20_ORIENTACIONES_%20TALENTOS%20EXEPCIONALES.pdf"
                text={<><b>Documento de orientaciones técnicas, administrativas y pedagógicas para la atención educativa a estudiantes con capacidades y/o con talentos excepcionales en el marco de la educación inclusiva.
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://www.mineducacion.gov.co/1780/articles-360295_foto_portada.pdf"
                text={<><b>Orientaciones generales para la escuela y la familia en la atención educativa a estudiantes con capacidades y talentos excepcionales
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://escuelasecretarias.mineducacion.gov.co/sites/default/files/2022-08/orientacionparalatransicion.pdf"
                text={<><b>Orientaciones para la transición educativa de los estudiantes con discapacidad y con capacidades o talentos excepcionales en la educación inicial, básica y media.
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://www.colombiaaprende.edu.co/recurso-coleccion/guias-y-cartillas-orientadoras"
                text={<><b>Colección Trayectorias Educativas Completas. Guías y cartillas orientadoras.
                </b> Colección conformada por tres cartillas y tres guías que abordan temáticas importantes para la atención educativa de la población con discapacidad. Contiene guías para la permanencia, la gestión escolar, el acompañamiento a familias y recursos para todos los actores del sistema educativo que acompañan a esta población.</>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link=""
                text={<><b>Recursos del grupo de investigación.
                </b></>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-2"
                link="https://udlguidelines.cast.org/static/udlg3-graphicorganizer_spanish_update_8142024.pdf"
                text={<><b>Guía DUA 3.0. 
                </b> Organizador gráfico de las Pautas 3.0 del diseño universal para el aprendizaje. </>}
              />
            </Grid>
            <Grid item xs={12} lg={4} className='cards-container'>
              <CardInfo 
                color="color-1"
                link="https://www.cast.org/about/about-cast"
                text={<><b>Página web CAST.
                </b> Es una organización sin fines de lucro dedicada a la investigación y desarrollo educativo, creadora del marco de Diseño Universal para el Aprendizaje (DUA) y las Directrices DUA, utilizadas en todo el mundo para hacer que el aprendizaje sea más inclusivo.</>}
              />
          </Grid>
        </Grid>
        <Grid container spacing={3} item xs={12} lg={4} className='law-section'>
          <Grid container className='cards-info-container'>
            <Grid container item xs={12} lg={4} className='img-item'>
              <img src={icon_paper} alt="Icono de hoja de papel" className='info-img' />
            </Grid>
            <Grid container item xs={12} lg={5} className='text-container-2'>
              <Typography className='text'>
                Normativa
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={11} className='cards-container-2'>
            <Box
              sx={{
                maxHeight: '1400px',
                overflowY: 'auto',
              }}
            >
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación en el marco de la Inclusión y la equidad en la educación
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=22106"
                text={<><b>Ley 1098 de 2006. 
                </b> Por la cual se expide el Código de la Infancia y la Adolescencia.</>}
                position="first"
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=77913"
                text={<><b>Decreto 1075 de 2015.
                </b> "Por medio del cual se expide el Decreto Único Reglamentario del Sector Educación".</>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=38741"
                text={<><b>Ley 1381 de 2010.
                </b> Por la cual se desarrollan los artículos 7°, 8°, 10 y 70 de la Constitución Política, y los artículos 4°, 5° y 28 de la Ley 21 de 1991 (que aprueba el Convenio 169 de la OIT sobre pueblos indígenas y tribales), y se dictan normas sobre reconocimiento, fomento, protección, uso, preservación y fortalecimiento de las lenguas de los grupos étnicos de Colombia y sobre sus derechos lingüísticos y los de sus hablantes.</>}
              />
              <CardText
                link="https://www.icbf.gov.co/cargues/avance/compilacion/docs/decreto_1470_2013.htm"
                text={<><b>Decreto 2500 de 2010.
                </b> Por el cual se reglamenta de manera transitoria la contratación de la administración de la atención educativa por parte de las entidades territoriales certificadas, con los cabildos, autoridades tradicionales indígenas, asociación de autoridades tradicionales indígenas y organizaciones indígenas en el marco del proceso de construcción e implementación del sistema educativo indígena propio SEIP. </>}
              />
              <CardText
                link="https://www.mineducacion.gov.co/1759/articles-157662_archivo_pdf.pdf"
                text={<><b>Circular No. 007 de 2008.
                </b> Orientaciones para la administración de programas de alfabetización y educación básica y media de jóvenes y adultos. Fecha: abril 7 de 2008.</>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=66614"
                text={<><b>Decreto 2383 de 2015.
                </b> Por el cual se reglamenta la prestación del servicio educativo en el marco del Sistema de Responsabilidad Penal para Adolescentes y se adiciona al Decreto 1075 de 2015. Único Reglamentario del Sector Educación.  </>}
              />
              <CardText
                link="https://colaboracion.dnp.gov.co/CDT/Conpes/Econ%C3%B3micos/3988.pdf"
                text={<><b>Documento Conpes Social 3988 de 2020.
                </b> Consejo Nacional de Política Económica y Social República de Colombia Departamento Nacional de Planeación. Tecnologías para aprender: Política nacional para impulsar la innovación en las prácticas educativas a través de las tecnologías digitales.</>}
              />
              <CardText
                link="https://www.cundinamarca.gov.co/wcm/connect/4d9308a2-d455-4873-9711-b3a3c5bf0644/Resoluci%C3%B3n+1519+de+2020.pdf?MOD=AJPERES&CONVERT_TO=url&CACHEID=ROOTWORKSPACE-4d9308a2-d455-4873-9711-b3a3c5bf0644-oVo7PWv"
                text={<><b>Resolución N° 001519 de 2020.
                </b> "Por la cual se definen los estándares y directrices para publicar la información señalada en la Ley 1712 del 2014 y se definen los requisitos materia de acceso a la información pública, accesibilidad web, seguridad digital, y datos abiertos".</>}
              />
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación de las personas con discapacidad en el marco de la educación inclusiva y equitativa
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.un.org/esa/socdev/enable/documents/tccconvs.pdf"
                text={<><b>Convención sobre los derechos de las personas con discapacidad
                </b> (ONU, 2006). </>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=87040"
                text={<><b>Decreto 1421 de 2017.
                </b> "Por el cual se reglamenta en el marco de la educación inclusiva la atención educativa a población con discapacidad". </>}
              />
              <CardText
                link="https://www.minsalud.gov.co/sites/rid/Lists/BibliotecaDigital/RIDE/DE/DIJ/resolucion-1197-de-2024.pdf"
                text={<><b>Resolución 1197 de 2024.
                </b>"Por la cual se establecen los criterios, requisitos y procedimiento para la certificación de discapacidad y el Registro de Localización y Caracterización de Personas con Discapacidad" .  </>}
              />
              <Grid item xs={12} className='text-container-3'>
                <Grid xs={11}>
                  <Typography className='title'>
                    Derecho a la educación de las personas en condición de enfermedad en el marco de la educación inclusiva y equitativa
                  </Typography>
                </Grid>
              </Grid>
              <CardText
                link="https://www.corteconstitucional.gov.co/relatoria/2009/T-294-09.htm"
                text={<><b>Sentencia T-294/09.
                </b> Derecho a la educación de menores con talentos o capacidades excepcionales - Obligaciones del Estado. </>}
              />
              <CardText
                link="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=188289"
                text={<><b>Ley 2216 de 2022.
                </b> "Por medio de la cual se promueve la educación inclusiva y el desarrollo integral de niñas, niños, adolescentes y jóvenes con trastornos específicos de aprendizaje".  </>}
              />
              <CardText
                link="https://www.icbf.gov.co/cargues/avance/compilacion/docs/decreto_1470_2013.htm"
                text={<><b>Decreto 1470 de 2013. 
                </b> "Por  medio del cual se reglamenta el Apoyo Académico Especial regulado en la Ley 1384  de 2010 y Ley 1388 de 2010 para la población menor de 18 años".</>}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};