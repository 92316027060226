import './HomeSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import icon_play from '../../../assets/images/play_icon.svg';
import info_img from '../../../assets/images/img_info.png';
import actor_img from '../../../assets/images/img_actor.png';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';
import logo_prov from '../../../assets/images/logo_prev_banner.png';
import { useNavigate } from 'react-router-dom';

const CardInfo = ({ img, imgDesc, text, position }: any) => {

  return (
      <>  
        <hr className={position}/>
        <Grid container className='card-info-container'>
          <Grid container item xs={12} lg={5} className='img-item'>
            <img src={img} alt={imgDesc} className='info-img' />
          </Grid>
          <Grid container item xs={12} lg={6} className='text-container'>
            <Typography className='text'>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </>
  );
}

const CardActor = ({ title, img, imgDesc, text, color, action }: any) => {

  return (
      <>  
          <Grid container className='card-actor-container' onClick={action}>
            <Grid container item xs={12} className={`title-container ${color}`}>
              <Typography className='title'>
                {title}
              </Typography>
            </Grid>
            <Grid container item xs={12} className='img-item'>
              <img src={img} alt={imgDesc} className='info-img' />
            </Grid>
            <Grid container item xs={12} className='text-container'>
              <Typography className='text'>
                {text}
              </Typography>
            </Grid>
          </Grid>
      </>
  );
}

export const HomeSection = () => {
  
  const navigate = useNavigate();

  const goToLiderazgo = () => {
    navigate('/liderazgo-desde-el-ministerio');
  };

  const goToRegiones = () => {
    navigate('/cambio-en-las-regiones');
  };

  const goToAula = () => {
    navigate('/transformacion-desde-el-aula');
  };

  const goToVoces = () => {
    navigate('/voces-familias-y-estudiantes');
  };

  return (
    <>

      {/* Banner section */}
      <Grid container className='Home-banner-container'>
        <Grid item xs={12} lg={6} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
          <div className='right-border'></div>
          <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
        </Grid>
        <Grid item xs={12} lg={6} className='logos-right'>
          <img className="logo-1" alt="Logo provisional" src={logo_prov} />
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Home-description-container'>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11}>
            <Typography className='title'>
              Título principal (Descripción general de la iniciativa)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper rhoncus sapien sed commodo. Nunc iaculis
              ipsum ut vestibulum laoreet. Cras eu auctor libero. orbi mattis erat vitae arcu ornare aliquet. Curabitur a tincidunt ex.
              Nam quam libero, cursus nec lacinia et, eleifend facilisis purus. Curabitur bibendum lectus mollis.
            </Typography>
            <br/>
            <Typography className='text-info'>
              augue molestie, mattis dapibus augue aliquet. Mauris ut ex nisl. Nunc non quam nec mauris suscipit interdum id a orci.
              Phasellus commodo maximus ullamcorper. Praesent facilisis dapibus cursus. Praesent gravida pulvinar elit, in sagittis
              justo fermentum id. Integer dapibus interdum eros, vitae venenatis arcu sodales id.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Media section */}
      <Grid container className='Home-media-container'>
        <Grid container item xs={12} lg={6} className='first-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Título video
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container item xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={9}>
                  <Typography className='text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nulla ullamcorper rhoncus sapien sed commodo.
                    Nunc iaculis ipsum ut vestibulum laoreet. Cras eu
                    auctor libero. Morbi mattis erat vitae arcu ornare
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='second-section'>
          <Grid item xs={12} className='cards-container'>
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
              position="first"
            />
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
            />
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Actores section */}
      <Grid container className='Home-actores-container'>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11}>
            <Typography className='title'>
              Acciones desde los diferentes actores
            </Typography>
          </Grid>
        </Grid>
        <Grid container className='cards-actor-container'>
          <Grid item xs={12} lg={3} className='card-actor-space'>
            <CardActor 
              title="Liderazgo desde el Ministerio"
              img={actor_img}
              imgDesc="Imagen actor"
              text="Espacio para funcionarios del
              MEN con información clave sobre
              políticas y estrategias inclusivas"
              color="blue-color"
              action={goToLiderazgo}
            />
          </Grid>
          <Grid item xs={12} lg={3} className='card-actor-space'>
            <CardActor 
              title="El cambio en las regiones"
              img={actor_img}
              imgDesc="Imagen actor"
              text="Espacio para funcionarios del           
              MEN con información clave sobre
              políticas y estrategias inclusivas"
              color="orange-color"
              action={goToRegiones}
            />
          </Grid>
          <Grid item xs={12} lg={3} className='card-actor-space'>
            <CardActor 
              title="Transformación desde el aula"
              img={actor_img}
              imgDesc="Imagen actor"
              text="Lugar para directivos y docentes
              enfocados en transformar la
              enseñanza a través de la educación"
              color="pink-color"
              action={goToAula}
            />
          </Grid>
          <Grid item xs={12} lg={3} className='card-actor-space'>
            <CardActor 
              title="Voces de las familias y estudiantes"
              img={actor_img}
              imgDesc="Imagen actor"
              text="Espacio con historias de estudiantes
              y familias que demuestran que la
              inclusión es posible"
              color="purple-color"
              action={goToVoces}
            />
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};