import './VocesSection.scss';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import icon_play from '../../../assets/images/play_icon_3.svg';
import info_img from '../../../assets/images/img_info.png';
import logo_educacion from '../../../assets/images/logo_educacion.png';
import logo_unal from '../../../assets/images/logo_unal.png';

const CardInfo = ({ img, imgDesc, text, position }: any) => {

  return (
      <>  
        <hr className={position}/>
        <Grid container className='card-info-container'>
          <Grid container item xs={12} lg={5} className='img-item'>
            <img src={img} alt={imgDesc} className='info-img' />
          </Grid>
          <Grid container item xs={12} lg={6} className='text-container'>
            <Typography className='text'>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </>
  );
}

export const VocesSection = () => {
  return (
    <>

      {/* Banner section */}
      <Grid container className='Voces-banner-container'>
        <Grid item xs={12} lg={4} className='logos-left'>
          <img className="logo-1" alt="Logo Ministerio de educación" src={logo_educacion} />
          <div className='right-border'></div>
          <img className="logo-2" alt="Logo Universidad Nacional de Colombia" src={logo_unal} />
        </Grid>
        <Grid item xs={12} lg={8} className='content-right'>
          <h1 className='title'><b>Voces de las familias y estudiantes</b></h1>
        </Grid>
      </Grid>

      {/* Description section */}
      <Grid container className='Voces-description-container'>
        <Grid item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Subtítulo
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='text-container'>
          <Grid item xs={11} lg={10}>
            <Typography className='text-info'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ullamcorper rhoncus sapien sed commodo. Nunc iaculis
              ipsum ut vestibulum laoreet. Cras eu auctor libero. orbi mattis erat vitae arcu ornare aliquet. Curabitur a tincidunt ex.
              Nam quam libero, cursus nec lacinia et, eleifend facilisis purus. Curabitur bibendum lectus mollis.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Media section */}
      <Grid container className='Voces-media-container'>
        <Grid container item xs={12} lg={6} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Título video
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={9}>
                  <Typography className='text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nulla ullamcorper rhoncus sapien sed commodo.
                    Nunc iaculis ipsum ut vestibulum laoreet. Cras eu
                    auctor libero. Morbi mattis erat vitae arcu ornare
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='media-section'>
          <Grid item xs={10}>
            <Grid item xs={12} className='info-container'>
              <Typography className='title'>
                Título video
              </Typography>
            </Grid>
            <Grid item xs={12} className='info-container'>
              <video controls className='video-item'>
                <source src={""} type="video/mp4" />
                  Tu navegador no soporta el formato de video.
              </video>
            </Grid>
            <Grid container xs={12} className='info-container'>
              <div className='video-item'>
                <Grid item xs={2}>
                  <img src={icon_play} alt='Icono de play' className='info-img' />
                </Grid>
                <Grid item xs={9}>
                  <Typography className='text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nulla ullamcorper rhoncus sapien sed commodo.
                    Nunc iaculis ipsum ut vestibulum laoreet. Cras eu
                    auctor libero. Morbi mattis erat vitae arcu ornare
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Repository section */}
      <Grid container className='Voces-repository-container'>
        <Grid container item xs={12} className='text-container'>
          <Grid xs={11}>
            <Typography className='title'>
              Biblioteca de políticas y lineamientos
            </Typography>
          </Grid>
          <Grid xs={11}>
            <Typography className='subtitle'>
              Repositorio con documentos y material audiovisual realizado con el MEN
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='resources-section'>
          <Grid item xs={12} className='cards-container'>
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
              position="first"
            />
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
            />
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6} className='resources-section'>
          <Grid item xs={12} className='cards-container'>
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
              position="first"
            />
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
            />
            <CardInfo 
              img={info_img}
              imgDesc="Imagen recurso"
              text="Lorem ipsum dolor sit amet, onsectetur
              adipiscing elit. Nulla ullamcorper
              rhoncus sapien sed commodo.
              Nunc iaculis ipsum ut vestibulum
              laoreet. Cras eu auctor libero. Morbi
              mattis erat vitae arcu ornare"
            />
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};